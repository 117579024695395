<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="8" md="8" sm="8">
        <v-row>
          <v-col md="6">
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <span class="font-weight-700 custom-headline color-custom-blue">
                  Skill Certificate
                </span>
              </v-card-title>
              <v-card-text class="pa-6 font-size-16">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >Skill Certificate</label
                  >
                  <v-select
                    :items="skillCertificate"
                    dense
                    filled
                    placeholder="Skills Certificate"
                    solo
                    v-model="skillLicens.skillCertificate"
                    flat
                    item-color="cyan"
                    item-text="title"
                    item-value="value"
                    multiple
                  ></v-select>
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label
                    for="mobile_number"
                    class="font-weight-700 font-size-16"
                    >Start Date</label
                  >
                  <DatePicker
                    placeholder="Start Date"
                    v-model="skillLicens.start_skill_date"
                  />
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >Expire Date</label
                  >
                  <DatePicker
                    placeholder="Expire Date"
                    :minDate="skillLicens.start_skill_date"
                    v-model="skillLicens.expire_skill_date"
                  />
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <span class="font-weight-700 custom-headline color-custom-blue">
                  License Certificate
                </span>
              </v-card-title>
              <v-card-text class="pa-6 font-size-16">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >License Certificate</label
                  >
                  <v-select
                    :items="licenseCertificate"
                    v-model.trim="skillLicens.licensCertificate"
                    dense
                    filled
                    placeholder="License Certificate"
                    solo
                    flat
                    item-color="cyan"
                    item-text="title"
                    item-value="value"
                    multiple
                  ></v-select>
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label
                    for="mobile_number"
                    class="font-weight-700 font-size-16"
                    >Start Date</label
                  >
                  <DatePicker
                    placeholder="Start Date"
                    v-model="skillLicens.start_licens_date"
                  />
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16"
                    >Expire Date</label
                  >
                  <DatePicker
                    placeholder="Expire Date"
                    v-model="skillLicens.expire_licens_date"
                    :minDate="skillLicens.start_licens_date"
                  />
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import { GET } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
/*   import PhoneTextField from "@/view/pages/partials/PhoneTextField"; */
import DatePicker from "@/view/components/QDatePicker.vue";

export default {
  name: "skill-license",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      licenseCertificate: [],
      skillCertificate: [],
      skillLicens: {
        skillCertificate: [],
        licensCertificate: [],
        start_skill_date: null,
        expire_skill_date: null,
        start_licens_date: null,
        expire_licens_date: null,
      },
      fieldDescriptions: [],
    };
  },
  watch: {
    skillLicens: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:skill-licence", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setSkillLicense();
    },
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "engineer/options",
        })
        .then(({ data }) => {
          _this.skillCertificate = this.lodash.map(
            data.skillCertificate,
            function (row) {
              return {
                title: row.text,
                value: row.value.toLowerCase(),
              };
            }
          );
          _this.licenseCertificate = this.lodash.map(
            data.licenseCertificate,
            function (row) {
              return {
                title: row.text,
                value: row.value.toLowerCase(),
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    setSkillLicense() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.skillLicens.licensCertificate = _this.engineer.licensCertificate;
        _this.skillLicens.skillCertificate = _this.engineer.skillCertificate;
        _this.skillLicens.start_licens_date = _this.engineer.start_licens_date;
        _this.skillLicens.expire_licens_date =
          _this.engineer.expire_licens_date;
        _this.skillLicens.start_skill_date = _this.engineer.start_skill_date;
        _this.skillLicens.expire_skill_date = _this.engineer.expire_skill_date;
        /*    _this.skillLicens.emergency_country_name =
            _this.engineer.emergency_country_name;
          _this.skillLicens.relation =
            _this.engineer.emergency_contact_relation;
          _this.skillLicens.email = _this.engineer.emergency_contact_email; */
      }
    },
  },
  components: {
    DatePicker,
  },
  mounted() {
    this.setSkillLicense();
    this.getOptions();
  },
};
</script>
