<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="6">
            <v-card flat class="custom-grey-border remove-border-radius h-100">
              <v-card-title class="headline grey lighten-4">
                <span class="font-weight-700 custom-headline color-custom-blue"
                  >Contact Information</span
                >
              </v-card-title>
              <v-card-text class="pa-6 font-size-16">
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label for="full_name" class="font-weight-700 font-size-16"
                    >Full name</label
                  >
                  <v-text-field
                    id="full_name"
                    v-model.trim="emergencyContact.name"
                    dense
                    filled
                    placeholder="Full name"
                    solo
                    flat
                  ></v-text-field>
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label
                    for="mobile_number"
                    class="font-weight-700 font-size-16"
                    >Phone number</label
                  >
                  <PhoneTextField
                    id="mobile_number"
                    v-model="emergencyContact.mobile"
                    :value="emergencyContact.mobile"
                  >
                  </PhoneTextField>
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16">Email</label>
                  <v-text-field
                    v-model.trim="emergencyContact.email"
                    :rules="[
                      validateRules.validEmail(emergencyContact.email, 'Email'),
                      validateRules.minLength(
                        emergencyContact.email,
                        'Email',
                        1
                      ),
                      validateRules.maxLength(
                        emergencyContact.email,
                        'Email',
                        255
                      ),
                    ]"
                    dense
                    filled
                    placeholder="Email"
                    solo
                    flat
                  ></v-text-field>
                </template>
                <template v-if="updateEngineer && pageLoading">
                  <v-skeleton-loader
                    class="custom-skeleton my-2 custom-text-height-40px"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <label class="font-weight-700 font-size-16">Relation</label>
                  <v-text-field
                    v-model.trim="emergencyContact.relation"
                    :rules="[
                      validateRules.minLength(
                        emergencyContact.relation,
                        'Relation',
                        1
                      ),
                      validateRules.maxLength(
                        emergencyContact.relation,
                        'Relation',
                        255
                      ),
                    ]"
                    dense
                    filled
                    placeholder="Relation"
                    solo
                    flat
                  ></v-text-field>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card flat class="custom-grey-border remove-border-radius h-100">
              <v-card-title class="headline grey lighten-4">
                <span class="font-weight-700 custom-headline color-custom-blue"
                  >Address Information</span
                >
              </v-card-title>
              <v-card-text class="pa-6 font-size-16">
                <v-row>
                  <v-col md="6" lg="6">
                    <label class="font-size-16 font-weight-500 required"
                      >Country
                    </label>
                    <v-autocomplete
                      :items="countryList"
                      v-model="emergencyContact.emergency_country_name"
                      hide-details
                      flat
                      :rules="[
                        validateRules.required(
                          emergencyContact.emergency_country_name,
                          'Country'
                        ),
                      ]"
                      solo
                      dense
                      item-value="value"
                      item-text="text"
                      placeholder="Country"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="countryCheck($event)"
                      class="pt-0 mt-0"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6" lg="6">
                    <label class="font-size-16 font-weight-500"
                      >Search Address
                    </label>
                    <SearchAddress
                      variant="outlined"
                      density="compact"
                      hide-details
                      :country="emergencyContact.emergency_country_name"
                      :country-code="country_code"
                      @update:address="(data) => setAddress(data)"
                      :placeholder="'Search Address'"
                    />
                  </v-col>
                </v-row>
                <template
                  v-if="emergencyContact.emergency_country_name == 'singapore'"
                >
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16">Unit no.</label>
                    <v-text-field
                      v-model.trim="emergencyContact.unit_no"
                      :rules="[
                        validateRules.minLength(
                          emergencyContact.unit_no,
                          'Unit no.',
                          1
                        ),
                        validateRules.maxLength(
                          emergencyContact.unit_no,
                          'Unit no.',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Unit no."
                      solo
                      flat
                    ></v-text-field>
                  </template>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16"
                      >Address Line 1</label
                    >
                    <v-text-field
                      v-model.trim="emergencyContact.street_1"
                      :rules="[
                        validateRules.minLength(
                          emergencyContact.street_1,
                          'Address Line 1',
                          1
                        ),
                        validateRules.maxLength(
                          emergencyContact.street_1,
                          'Address Line 1',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Address Line 1"
                      solo
                      flat
                    ></v-text-field>
                  </template>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16"
                      >Address Line 2</label
                    >
                    <v-text-field
                      v-model.trim="emergencyContact.street_2"
                      :rules="[
                        validateRules.minLength(
                          emergencyContact.street_2,
                          'Address Line 2',
                          1
                        ),
                        validateRules.maxLength(
                          emergencyContact.street_2,
                          'Address Line 2',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Address Line 2"
                      solo
                      flat
                    ></v-text-field>
                  </template>
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16"
                      >Postal Code</label
                    >
                    <v-text-field
                      v-model.trim="emergencyContact.postal_code"
                      :rules="[
                        validateRules.minLength(
                          emergencyContact.postal_code,
                          'Postal Code',
                          1
                        ),
                        validateRules.maxLength(
                          emergencyContact.postal_code,
                          'Postal Code',
                          255
                        ),
                      ]"
                      dense
                      filled
                      v-mask="'######'"
                      placeholder="Postal Code"
                      solo
                      flat
                    ></v-text-field>
                  </template>
                </template>
                <template v-else>
                  <label class="font-size-16 font-weight-500 required"
                    >Full Address</label
                  >
                  <v-textarea
                    v-model.trim="emergencyContact.street_1"
                    :rules="[
                      validateRules.required(
                        emergencyContact.street_1,
                        'Full Address'
                      ),
                      validateRules.maxLength(
                        emergencyContact.street_1,
                        'Full Address',
                        255
                      ),
                    ]"
                    auto-grow
                    dense
                    filled
                    placeholder="Full Address"
                    solo
                    flat
                    row-height="15"
                    color="cyan"
                  ></v-textarea>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import SearchAddress from "@/view/components/Search-Address";

export default {
  name: "emergency-contact",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      country_code: "sg",
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      emergencyContact: {
        name: null,
        mobile: null,
        relation: null,
        unit_no: null,
        street_1: null,
        street_2: null,
        postal_code: null,
        email: null,
        emergency_country_name: "singapore",
      },
      fieldDescriptions: [],
    };
  },
  watch: {
    emergencyContact: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:emergency-contact", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setEmergencyContact();
    },
  },
  methods: {
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });
      this.country_code = code.country_code;
      this.emergencyContact.postal_code = null;
      this.emergencyContact.street_1 = null;
      this.emergencyContact.street_2 = null;
      this.emergencyContact.unit_no = null;
      // this.emergencyContact.longitude = null;
      // this.emergencyContact.latitude = null;
    },
    setAddress(e) {
      this.emergencyContact.postal_code = this.lodash.cloneDeep(e.postal_code);
      this.emergencyContact.street_1 = e.address1;
      this.emergencyContact.street_2 = e.address2;
      this.emergencyContact.unit_no = e.unit_number;
      // this.emergencyContact.longitude = e.longitude;
      // this.emergencyContact.latitude = e.latitude;
    },
    setEmergencyContact() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.emergencyContact.name = _this.engineer.emergency_contact_name;
        _this.emergencyContact.mobile = _this.engineer.emergency_contact_number;
        _this.emergencyContact.unit_no =
          _this.engineer.emergency_contact_unit_no;
        _this.emergencyContact.street_1 =
          _this.engineer.emergency_contact_street_1;
        _this.emergencyContact.street_2 =
          _this.engineer.emergency_contact_street_2;
        _this.emergencyContact.postal_code =
          _this.engineer.emergency_contact_postal_code;
        _this.emergencyContact.emergency_country_name =
          _this.engineer.emergency_country_name;
        _this.emergencyContact.relation =
          _this.engineer.emergency_contact_relation;
        _this.emergencyContact.email = _this.engineer.emergency_contact_email;
        let code = "";
        code = this.lodash.find(this.countryList, function (row) {
          if (row.value == _this.engineer.emergency_country_name) {
            return row.country_code;
          } else {
            return false;
          }
        });
        this.country_code = code.country_code;
      }
    },
  },
  components: {
    PhoneTextField,
    SearchAddress,
  },
  mounted() {
    this.setEmergencyContact();
  },
};
</script>
